<template>
  <div class="wrapper">
    <div class="card">
      <div class="top">
        <div class="product-name">{{ productName }}</div>
        <div class="applied-time">申请日期：{{ appliedDate }}</div>
      </div>
      <div class="bottom">
        <div class="status">状态：{{ status }}</div>
        <div v-if="statusId === 20" class="detail">
          <div class="row">
            <div>获批金额：{{ approvedAmount }}万元</div>
            <div>{{ rate }}</div>
          </div>
          <div class="row">
            <div>{{ term }}</div>
            <div>还款方式：{{ repaymentType }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-button
      v-if="showNextBtn"
      class="continue-btn"
      color="#8249E1"
      block
      :loading="nextBtnLoading"
      :disabled="nextBtnDisabled"
      @click="nextStep"
      >{{ nextBtnText }}</van-button
    >
  </div>
</template>

<script>
import CustomerOrderApi from "../../api/customerOrder";
import { Button, Toast, Dialog } from "vant";
import DashuApi from "../../api/dashu";

export default {
  name: "StatusPage",
  components: {
    [Button.name]: Button,
  },
  props: {
    orderId: String,
  },
  data() {
    return {
      orgId: "",
      staffId: "",
      v: "",
      productCode: "",
      productName: "",
      status: "",
      statusId: "",
      appliedDate: "",
      approvedAmount: null,
      hasTaxAuthorized: null,
      isHuimingdai: null,
      rate: "",
      term: "",
      repaymentType: "",
      showNextBtn: true,
      nextBtnLoading: false,
      nextBtnText: "继续操作",
      nextBtnDisabled: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        className: "loading-toast",
      });
      CustomerOrderApi.orderStatus(this.orderId)
        .then((res) => {
          this.orgId = res.data.orgId;
          this.staffId = res.data.staffId;
          this.v = res.data.v;
          this.productCode = res.data.productCode;
          this.productName = res.data.productName;
          this.status = res.data.status;
          this.statusId = res.data.statusId;
          this.appliedDate = res.data.appliedDate;
          this.approvedAmount = res.data.approvedAmount;
          this.hasTaxAuthorized = res.data.hasTaxAuthorized;
          this.isHuimingdai = res.data.isHuimingdai;
          this.rate = res.data.rate;
          this.term = res.data.term;
          this.repaymentType = res.data.repaymentType;
          this.initNextBtn();
        })
        .catch((res) => {
          if (res.code === "901") {
            this.authInvalid();
          } else {
            Dialog({
              message: res.msg,
              showConfirmButton: false,
            });
          }
        })
        .finally(() => {
          Toast.clear();
        });
    },
    initNextBtn() {
      if (this.statusId === 90) {
        this.showNextBtn = false;
      } else if (this.statusId > 90) {
        this.nextBtnText = "再次申请";
        this.showNextBtn = true;
      } else if (this.statusId === 20 && this.productCode !== "A6") {
        this.nextBtnText = "联系客户经理提款";
        this.nextBtnDisabled = true;
      } else if (this.productCode === "A5") {
        this.nextBtnText = "授权完成，数据核对中";
        this.nextBtnDisabled = true;
      }
    },
    authInvalid() {
      Dialog({
        message: "授权已失效",
        showConfirmButton: false,
      });
    },
    nextStep() {
      this.nextBtnLoading = true;
      if (this.nextBtnText === "再次申请") {
        this.$router.push(
          `/apply/${this.productCode}?orgId=${this.orgId}&staffId=${this.staffId}&v=${this.v}&newOrder=true`
        );
      } else {
        if (this.isHuimingdai) {
          if (!this.hasTaxAuthorized) {
            this.$router.push("/apply/hmd/" + this.orderId);
          } else if (this.productCode === "A10") {
            location.href =
              "https://lsd.lsccb.com/cfs_server/wx/wxBind.do?wxchannel=LS&outchannel=jz_ch";
          } else if (this.productCode === "A7") {
            this.$router.push("/pingan");
          } else {
            this.showNextBtn = false;
            this.nextBtnLoading = false;
            Dialog.alert({
              message: "业务处理中，请稍后查看",
            });
          }
        } else {
          if (this.statusId < 20) {
            if (this.productCode === "A5") {
              if (this.statusId === 13) {
                this.nextBtnText = "授权完成，数据核对中";
                this.nextBtnDisabled = true;
              } else {
                location.href =
                  "http://t.hanhua.com/tong2/prod/hxdans/hxdlogin?busicode=C2019073100000047&businesstype=101300036&advert=1";
              }
            } else if (this.productCode === "A6") {
              DashuApi.link(this.orderId)
                .then((res) => {
                  location.href = res.data;
                })
                .catch(() => {
                  this.nextBtnLoading = false;
                });
            } else {
              this.showNextBtn = false;
              this.nextBtnLoading = false;
              Dialog.alert({
                message: "业务处理中，请稍后查看",
              });
            }
          } else if (this.statusId === 20) {
            this.$router.push("/apply/withdrawals/" + this.orderId);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 30px;
  font-size: 28px;
}

.card {
  background-color: #b390ee;
  border-radius: 15px;
  padding: 30px 30px;
  color: #ffffff;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 5px;
}

.applied-time {
  color: #ffffff;
  text-align: center;
}

.bottom {
  margin-top: 30px;
  /*display: flex;*/
  /*justify-content: space-between;*/
}

.continue-btn {
  margin-top: 50px;
  height: 80px;
  border-radius: 15px;
}

.detail {
  margin-top: 30px;
  line-height: 48px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.withdrawals-tips {
  color: #666666;
  margin-top: 80px;
}
</style>